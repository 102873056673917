import React from 'react';
import { AuthProvider, Refine } from '@pankod/refine-core';
import {
  notificationProvider,
  ReadyPage,
  ErrorComponent,
} from '@pankod/refine-antd';
import routerProvider from '@pankod/refine-react-router-v6';
import '@pankod/refine-antd/dist/styles.min.css';
import dataProvider from '@pankod/refine-simple-rest';
import 'antd-css-utilities/utility.min.css';

import {
  Title,
  Header,
  Sider,
  Footer,
  Layout,
  OffLayoutArea,
} from 'components/layout';
import { useTranslation } from 'react-i18next';
import './components/custom/styles.scss';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import feathersDataProvider from './dataProviders/feathers';

const Login = React.lazy(() => import('./pages/login'));
const CompanyPageEditor = React.lazy(() => import('./pages/companies/CompanyPageEditor'));
const OfferEdit = React.lazy(() => import('./pages/offers/edit'));
const OffersList = React.lazy(() => import('./pages/offers/list'));
const SponsorOffer = React.lazy(() => import('./pages/sponsored-offers/edit'));
const CompaniesList = React.lazy(() => import('./pages/companies/list'));
const CompanyEdit = React.lazy(() => import('./pages/companies/edit'));
const OfferModerationEdit = React.lazy(() => import('./pages/offers-moderation/edit'));
const OfferModerationShow = React.lazy(() => import('./pages/offers-moderation/show'));
const OffersModerationList = React.lazy(() => import('./pages/offers-moderation/list'));
const OfferModerationCreate = React.lazy(() => import('pages/offers-moderation/create'));
const RawOffersList = React.lazy(() => import('./pages/raw-offers/list'));
const CompanyUsersList = React.lazy(() => import('./pages/company-users/list'));
const CompanyUsersCreate = React.lazy(() => import('./pages/company-users/create'));
const CompanyUsersShow = React.lazy(() => import('./pages/company-users/show'));
const SponsoredOffersList = React.lazy(() => import('./pages/sponsored-offers/list'));
const SponsoredMatrix = React.lazy(() => import('./pages/sponsored-offers/sponsoring-matrix'));
const SponsoredMatrixLocation = React.lazy(() => import('./pages/sponsored-offers/sponsoring-matrix-location'));
const SponsorInvoicing = React.lazy(() => import('./pages/sponsored-offers/invoicing'));

function App() {
  const { t, i18n } = useTranslation();

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  const {
    isLoading,
    isAuthenticated,
    user,
    logout,
    getIdTokenClaims,
  } = useAuth0();

  if (isLoading) {
    return <span>loading...</span>;
  }

  const authProvider: AuthProvider = {
    login: () => Promise.resolve(),
    logout: () => {
      logout({ returnTo: window.location.origin });
      return Promise.resolve('/');
    },
    checkError: () => Promise.resolve(),
    checkAuth: () => {
      if (
        window.location.pathname.includes('/company-page-editor/edit')
        || (isAuthenticated && user?.email?.indexOf('@jobgether.com') !== -1)
      ) {
        return Promise.resolve();
      }

      return Promise.reject();
    },
    getPermissions: () => Promise.resolve(),
    getUserIdentity: async () => {
      if (user) {
        return Promise.resolve({
          ...user,
          avatar: user.picture || '',
        });
      }
      return Promise.reject();
    },
  };

  getIdTokenClaims().then((token) => {
    if (token) {
      axios.defaults.headers.common = {
        Authorization: `Bearer ${token.__raw}`,
      };
    }
  });

  return (
    <Refine
      routerProvider={{
        ...routerProvider,
        routes: [
          {
            element: <CompanyPageEditor />,
            path: '/company-page-editor/edit/:id',
          },
          {
            element: <SponsoredMatrix />,
            path: '/sponsored-offers/jr-matrix',
            layout: true,
          },
          {
            element: <SponsoredMatrixLocation />,
            path: '/sponsored-offers/location-matrix',
            layout: true,
          },
          {
            element: <SponsorInvoicing />,
            path: '/sponsored-offers/invoicing',
            layout: true,
          },
        ],
      }}
      notificationProvider={notificationProvider}
      ReadyPage={ReadyPage}
      catchAll={<ErrorComponent />}
      dataProvider={{
        notdefault: dataProvider('https://api.fake-rest.refine.dev'),
        default: feathersDataProvider(process.env.REACT_APP_DEV_URL || ''),
      }}
      authProvider={authProvider}
      LoginPage={Login}
      resources={[
        {
          name: 'company',
          list: CompaniesList,
          edit: CompanyEdit,
        },
        {
          options: {
            route: 'offers-moderation',
            label: 'Offers moderation',
          },
          name: 'offer-moderation',
          list: OffersModerationList,
          show: OfferModerationShow,
          create: OfferModerationCreate,
          edit: OfferModerationEdit,
        },
        {
          name: 'offer',
          options: {
            label: 'Moderated offers',
          },
          list: OffersList,
          edit: OfferEdit,
        },
        {
          name: 'sponsored-offers',
          options: {
            label: 'Sponsored offers',
          },
          list: SponsoredOffersList,
          edit: SponsorOffer,
        },
        {
          options: {
            route: 'raw-offers',
          },
          name: 'raw-offers',
          list: RawOffersList,
        },
        {
          options: {
            route: 'company-users/:company_id/',
            hidden: true,
          },
          name: 'company-users',
          list: CompanyUsersList,
          create: CompanyUsersCreate,
          show: CompanyUsersShow,
        },
      ]}
      Title={Title}
      Header={Header}
      Sider={Sider}
      Footer={Footer}
      Layout={Layout}
      OffLayoutArea={OffLayoutArea}
      i18nProvider={i18nProvider}
    />
  );
}

export default App;
