import React from 'react';
import routerProvider from '@pankod/refine-react-router-v6';
import { TitleProps } from '@pankod/refine-core';

const { Link } = routerProvider;

export const Title: React.FC<TitleProps> = ({ collapsed }) => (
  <Link to="/">
    {collapsed ? (
      <img
        src="/letra-g.png"
        alt="Jobgether"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '12px 24px',
          maxWidth: '100%',
        }}
      />
    ) : (
      <img
        src="/jobgether.logo.png"
        alt="Jobgether"
        style={{
          width: '200px',
          padding: '12px 24px',
        }}
      />
    )}
  </Link>
);
