import axios, { AxiosInstance } from 'axios';
import { stringify } from 'query-string';
import {
  DataProvider,
  HttpError,
  CrudOperators,
  CrudFilters,
  CrudSorting,
  BaseKey,
} from '@pankod/refine-core';
import Qs from 'qs';
import dayjs, { Dayjs } from 'dayjs';

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const customError: HttpError = {
      ...error,
      message: error.response?.data?.message,
      statusCode: error.response?.status,
    };

    return Promise.reject(customError);
  },
);

const mapOperator = (operator: CrudOperators): string => {
  switch (operator) {
    case 'in':
    case 'ne':
    case 'gte':
    case 'lte':
    case 'between':
      return `$${operator}`;
    case 'contains':
      return '$search';
    case 'eq':
    default:
      return '';
  }
};

const generateSort = (sort?: CrudSorting) => {
  if (sort && sort.length > 0) {
    const $sort: {[k:string]:number} = {};

    sort.forEach((item) => {
      $sort[`$sort[${item.field}]`] = item.order === 'asc' ? 1 : -1;
    });

    return $sort;
  }
  return [];
};

const generateFilter = (filters?: CrudFilters, searchMode: string = '') => {
  const queryFilters: { [key: string]: any } = {};
  if (filters) {
    filters.forEach((filter) => {
      if (filter.operator !== 'or') {
        const { field, operator, value } = filter;
        let newValue = value;

        if (newValue instanceof dayjs) {
          newValue = (newValue as Dayjs).toDate();
        } else if (Array.isArray(newValue)) {
          newValue = newValue.map((e) => ((e instanceof dayjs) ? (e as Dayjs).toDate() : e));
        }

        if (field === 'q') {
          queryFilters[field] = newValue;
          return;
        }

        if (operator === 'between') {
          if (Array.isArray(newValue) && newValue.length === 2) {
            if (dayjs(newValue[0]).isValid() && dayjs(newValue[1]).isValid()) {
              newValue[0] = dayjs(newValue[0]).toISOString();
              newValue[1] = dayjs(newValue[1]).toISOString();
            }
            queryFilters[field] = { $gte: newValue[0], $lte: newValue[1] };
          }
          return;
        }

        if (operator === 'eq') {
          if (searchMode === 'startWith') {
            queryFilters[field] = `^${newValue}`;
          } else {
            queryFilters[field] = newValue;
          }
        } else {
          const mappedOperator = mapOperator(operator);
          if (searchMode === 'startWith') {
            queryFilters[`${field}[${mappedOperator}]`] = `^${newValue}`;
          } else {
            queryFilters[`${field}[${mappedOperator}]`] = newValue;
          }
        }
      }
    });
  }

  return queryFilters;
};

const FeathersDataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axiosInstance,
): DataProvider => ({
  getList: async ({
    resource, pagination, filters, sort, metaData,
  }) => {
    const url = `${apiUrl}/${resource}`;
    const queryOptions = metaData?.options || {};
    const searchMode = metaData?.searchMode;

    // pagination
    const current = pagination?.current || 1;
    const pageSize = pagination?.pageSize || 10;

    const queryFilters = generateFilter(filters, searchMode);

    const generatedSort = generateSort(sort);

    const query: {
            $skip: number;
            $limit: number;
            [key: string]: any;
        } = {
          $skip: (current - 1) * pageSize,
          $limit: pageSize,
          ...generatedSort,
        };

    const { data } = await httpClient.get(url, {
      params: {
        ...metaData?.params,
        ...query,
        ...queryFilters,
        ...queryOptions,
        $select: metaData?.fields,
      },
      paramsSerializer: (params) => Qs.stringify(params, { arrayFormat: 'brackets' }),
    });

    return {
      data: data.data,
      total: data.total,
    };
  },

  getMany: async ({ ids, metaData, resource }) => {
    const searchKey = metaData?.searchKey || '_id';
    const queryOptions = metaData?.options || {};
    const filters = generateFilter(metaData?.filters);
    let query = {};
    let idsClone:string[] = [];

    if (Array.isArray(ids)) {
      idsClone = ids.reduce<string[]>((acc:string[], id: BaseKey) => {
        if (
          typeof id !== 'undefined' && id
        ) {
          const idStr = (typeof id !== 'string') ? id.toString().toLowerCase() : id.toLowerCase();
          if (acc.indexOf(idStr) === -1) {
            acc.push(idStr);
          }
        }
        return acc;
      }, []);
    }

    if (Array.isArray(searchKey)) {
      query = searchKey
        .reduce((_query, key) => ({
          ..._query,
          $or: [
            ..._query.$or || [],
            { [key]: { $in: idsClone } },
          ],
        }), {} as any);
    } else if (Array.isArray(idsClone) && idsClone.length === 1) {
      query = { [searchKey]: idsClone[0] };
    } else if (metaData?.operation === 'in') {
      query = { [`${searchKey}[$in]`]: idsClone };
    } else {
      query = { [searchKey]: idsClone };
    }

    const params = {
      ...metaData?.params,
      ...query,
      ...queryOptions,
      ...filters,
      '$collation[strength]': 1,
      '$collation[locale]': 'en',
      $select: metaData?.fields,
    };

    const { data } = (idsClone.length === 0) ? { data: { data: [] } } : await httpClient.get(
      `${apiUrl}/${resource}`,
      {
        params,
        paramsSerializer: (rawParams) => Qs.stringify(rawParams, { arrayFormat: 'brackets' }),
      },
    );

    return {
      data: data.data,
    };
  },

  create: async ({ resource, variables }) => {
    const url = `${apiUrl}/${resource}`;

    const { data } = await httpClient.post(url, variables);

    return {
      data,
    };
  },

  createMany: async ({ resource, variables }) => {
    const response = await Promise.all(
      variables.map(async (param) => {
        const { data } = await httpClient.post(
          `${apiUrl}/${resource}`,
          param,
        );
        return data;
      }),
    );

    return { data: response };
  },

  update: async ({ resource, id, variables }) => {
    const url = `${apiUrl}/${resource}/${id}`;

    const { data } = await httpClient.patch(url, variables);

    return {
      data,
    };
  },

  updateMany: async ({ resource, ids, variables }) => {
    const response = await Promise.all(
      ids.map(async (id) => {
        const { data } = await httpClient.patch(
          `${apiUrl}/${resource}/${id}`,
          variables,
        );
        return data;
      }),
    );

    return { data: response };
  },

  getOne: async ({ resource, id, metaData }) => {
    const url = `${apiUrl}/${resource}/${id}`;
    const queryOptions = metaData?.options || {};
    const params = metaData?.params || {};

    const { data } = await httpClient.get(url, {
      params: {
        ...params,
        ...queryOptions,
        $select: metaData?.fields,
      },
      paramsSerializer: (rawParams) => Qs.stringify(rawParams, { arrayFormat: 'brackets' }),
    });

    return {
      data,
    };
  },

  deleteOne: async ({ resource, id }) => {
    const url = `${apiUrl}/${resource}/${id}`;

    const { data } = await httpClient.delete(url);

    return {
      data,
    };
  },

  deleteMany: async ({ resource, ids }) => {
    const response = await Promise.all(
      ids.map(async (id) => {
        const { data } = await httpClient.delete(
          `${apiUrl}/${resource}/${id}`,
        );
        return data;
      }),
    );
    return { data: response };
  },

  getApiUrl: () => apiUrl,

  custom: async ({
    url, method, filters, sort, payload, query, headers,
  }) => {
    let requestUrl = `${url}?`;

    if (sort) {
      const generatedSort = generateSort(sort);
      if (generatedSort) {
        requestUrl = `${requestUrl}&${stringify(generatedSort)}`;
      }
    }

    if (filters) {
      const filterQuery = generateFilter(filters);
      requestUrl = `${requestUrl}&${stringify(filterQuery)}`;
    }

    if (query) {
      requestUrl = `${requestUrl}&${stringify(query)}`;
    }

    if (headers) {
      // eslint-disable-next-line no-param-reassign
      httpClient.defaults.headers = {
        ...httpClient.defaults.headers,
        ...headers,
      };
    }

    let axiosResponse;
    switch (method) {
      case 'put':
      case 'post':
      case 'patch':
        axiosResponse = await httpClient[method](url, payload);
        break;
      case 'delete':
        axiosResponse = await httpClient.delete(url);
        break;
      default:
        axiosResponse = await httpClient.get(requestUrl);
        break;
    }

    const { data } = axiosResponse;

    return Promise.resolve({ data });
  },
});

export default FeathersDataProvider;
